export const PROJECT_NAME = 'Bolver';
export const BASE_URL = 'https://api.bolversa.com';
export const WEBSITE_URL = 'https://bolversa.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyAIKCNOpmrXNNC21Y_oi9BGeo70z2pld3U';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
  'BCYX5nt_2Cck3h327mTknDfXY-YC-qAM0o9IpSMuOHCPzOfp1jLijUipRGvzNadGeYSat2sJ0_WV96fj2fR_Qt4';

export const RECAPTCHASITEKEY = '6LfFwnkpAAAAAH0Z_SF_PaL6TLiR5EjPUgemjIBn';
export const API_KEY = 'AIzaSyDD3ZtXTO5A3dV02prrSVs---oNEhEp1Vo';
export const AUTH_DOMAIN = 'bolver-13e46.firebaseapp.com';
export const PROJECT_ID = 'bolver-13e46';
export const STORAGE_BUCKET = 'bolver-13e46.appspot.com';
export const MESSAGING_SENDER_ID = '905151585140';
export const APP_ID = '1:905151585140:web:55c036626d338c3fadea21';
export const MEASUREMENT_ID = 'G-F5WGHJTY76';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id
